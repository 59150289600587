import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

import FooterNavigation from './FooterNavigation/FooterNavigation';
import FooterBottom from './FooterBottom/FooterBottom';

const Footer = ({ hideFooterNavigation }) => (
  <Box
    tag="footer"
    padding={{ xs: '8x', lg: '12x' }}
    backgroundColor="black.default"
    color="white.default"
  >
    {!hideFooterNavigation && <FooterNavigation />}
    <FooterBottom />
  </Box>
);

Footer.defaultProps = {
  hideFooterNavigation: false,
};

Footer.propTypes = {
  hideFooterNavigation: PropTypes.bool,
};

export default Footer;
