import React from 'react';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import styled from 'styled-components';

import FooterLogo from '../FooterLogo';
import FooterInfo from '../FooterInfo';

const StyledGridChild = styled(Grid.Child)`
  border-top: none;
  padding-top: 32px;
  margin-bottom: 120px;

  @media (width >= 768px) {
    border-top: 1px solid #6A6A6A;
    margin-bottom: 60px;
  }
`;

function FooterBottom() {
  return (
    <Grid
      gridTemplateColumns={{ xs: '1fr', lg: '1fr 1fr 1fr 1fr 1fr' }}
      paddingLeft="0"
      paddingRight="0"
    >
      <StyledGridChild
        gridColumn={{ xs: 'auto', lg: '1 / span 4' }}
      >
        {/* LOGO + INFO +  SOCIAL ICONS */}
        <FooterLogo />

        {/* ADDRESS + LEGAL LINKS */}
        <FooterInfo />

      </StyledGridChild>
    </Grid>
  );
}

export default FooterBottom;
