import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  display: flex;
  gap: 16px;
  width: 100%;

  svg:hover {
    opacity: 0.8;
  }

  li, a {
    display: block;
    height: 20px;
  }
`;

const FooterSocial = () => (
  <StyledBox
    display="flex"
    alignItems="center"
    tag="ul"
    justifyContent={{ xs: 'space-between', lg: 'flex-end' }}
    marginVertical={{ xs: '60px', lg: '0' }}
    maxWidth={{ xs: '450px', lg: '100%' }}
    margin="0 auto"
  >
    <li>
      <Link
        href="https://www.facebook.com/nu.mx"
        color="white"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20" color="currentColor" role="img">
          <title>Facebook</title>
          <g>
            <path fillRule="evenodd" clipRule="evenodd" d="M.936 2.184C.5 3.04.5 4.16.5 6.4v7.2c0 2.24 0 3.36.436 4.216a4 4 0 001.748 1.748C3.54 20 4.66 20 6.9 20h7.2c2.24 0 3.36 0 4.216-.436a4 4 0 001.748-1.748c.436-.856.436-1.976.436-4.216V6.4c0-2.24 0-3.36-.436-4.216A4 4 0 0018.316.436C17.46 0 16.34 0 14.1 0H6.9C4.66 0 3.54 0 2.684.436A4 4 0 00.936 2.184zM11.245 17.3v-6.743h2.84l.428-2.514h-3.268V6.87c0-.91.718-1.657 1.616-1.657h1.69V2.7h-1.69c-2.231 0-4.05 1.862-4.05 4.171v1.172h-2.36v2.514h2.36V17.3h2.434z" />
          </g>
        </svg>
      </Link>
    </li>

    <li>
      <Link
        href="https://www.instagram.com/nu.mx"
        color="white"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20" color="currentColor" role="img">
          <title>Instagram</title>
          <g>
            <path d="M10.5 7C8.84315 7 7.5 8.34315 7.5 10C7.5 11.6569 8.84315 13 10.5 13C12.1569 13 13.5 11.6569 13.5 10C13.5 8.34315 12.1569 7 10.5 7Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.935974 2.18404C0.5 3.03969 0.5 4.15979 0.5 6.4V13.6C0.5 15.8402 0.5 16.9603 0.935974 17.816C1.31947 18.5686 1.93139 19.1805 2.68404 19.564C3.53969 20 4.65979 20 6.9 20H14.1C16.3402 20 17.4603 20 18.316 19.564C19.0686 19.1805 19.6805 18.5686 20.064 17.816C20.5 16.9603 20.5 15.8402 20.5 13.6V6.4C20.5 4.15979 20.5 3.03969 20.064 2.18404C19.6805 1.43139 19.0686 0.819467 18.316 0.435974C17.4603 0 16.3402 0 14.1 0H6.9C4.65979 0 3.53969 0 2.68404 0.435974C1.93139 0.819467 1.31947 1.43139 0.935974 2.18404ZM16.5417 5.08333C17.117 5.08333 17.5833 4.61696 17.5833 4.04167C17.5833 3.46637 17.117 3 16.5417 3C15.9664 3 15.5 3.46637 15.5 4.04167C15.5 4.61696 15.9664 5.08333 16.5417 5.08333ZM5.5 10C5.5 7.23858 7.73858 5 10.5 5C13.2614 5 15.5 7.23858 15.5 10C15.5 12.7614 13.2614 15 10.5 15C7.73858 15 5.5 12.7614 5.5 10Z" />
          </g>
        </svg>
      </Link>
    </li>

    <li>
      <Link
        href="https://twitter.com/nu_mx"
        color="white"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20" color="currentColor" role="img">
          <title>X (Twitter)</title>
          <g>
            <path d="M0.936 2.184C0.5 3.04 0.5 4.16 0.5 6.4V13.6C0.5 15.84 0.5 16.96 0.936 17.816C1.32 18.568 1.932 19.18 2.684 19.564C3.54 20 4.66 20 6.9 20H14.1C16.34 20 17.46 20 18.316 19.564C19.068 19.18 19.68 18.568 20.064 17.816C20.5 16.96 20.5 15.84 20.5 13.6V6.4C20.5 4.16 20.5 3.04 20.064 2.184C19.68 1.432 19.068 0.82 18.316 0.436C17.46 0 16.34 0 14.1 0H6.9C4.66 0 3.54 0 2.684 0.436C1.932 0.82 1.32 1.432 0.936 2.184ZM15.5 5L11.836 9.236L16.1 15H13.508L10.756 11.164L7.612 15H5.5L9.444 10.421L5.4 5H8.064L10.548 8.532L13.412 5H15.5ZM13.776 13.8H14.712L7.272 6.104H6.28L13.776 13.8Z" />
          </g>
        </svg>
      </Link>
    </li>

    <li>
      <Link
        href="https://www.linkedin.com/company/nu-mexico"
        color="white"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20" color="currentColor" role="img">
          <title>Linkedin</title>
          <g>
            <path fillRule="evenodd" clipRule="evenodd" d="M.936 2.184C.5 3.04.5 4.16.5 6.4v7.2c0 2.24 0 3.36.436 4.216a4 4 0 001.748 1.748C3.54 20 4.66 20 6.9 20h7.2c2.24 0 3.36 0 4.216-.436a4 4 0 001.748-1.748c.436-.856.436-1.976.436-4.216V6.4c0-2.24 0-3.36-.436-4.216A4 4 0 0018.316.436C17.46 0 16.34 0 14.1 0H6.9C4.66 0 3.54 0 2.684.436A4 4 0 00.936 2.184zm3.73 4.483v9.166h2.5V6.667h-2.5zM4.459 4.14c0 .812.654 1.47 1.459 1.47.806 0 1.458-.658 1.458-1.47 0-.812-.653-1.47-1.458-1.47-.805 0-1.458.658-1.458 1.47zm10.209 11.693h2.5v-5.632c0-4.378-4.67-4.219-5.834-2.064v-1.47h-2.5v9.166h2.5v-4.67c0-2.594 3.334-2.806 3.334 0v4.67z" />
          </g>
        </svg>
      </Link>
    </li>

    <li>
      <Link
        href="https://youtube.com/channel/UC-Y2_nbByqsViBubK3QfHDw"
        color="white"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20" color="currentColor" role="img">
          <title>youtube</title>
          <g>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.935974 2.18404C0.5 3.03969 0.5 4.15979 0.5 6.4V13.6C0.5 15.8402 0.5 16.9603 0.935974 17.816C1.31947 18.5686 1.93139 19.1805 2.68404 19.564C3.53969 20 4.65979 20 6.9 20H14.1C16.3402 20 17.4603 20 18.316 19.564C19.0686 19.1805 19.6805 18.5686 20.064 17.816C20.5 16.9603 20.5 15.8402 20.5 13.6V6.4C20.5 4.15979 20.5 3.03969 20.064 2.18404C19.6805 1.43139 19.0686 0.819467 18.316 0.435974C17.4603 0 16.3402 0 14.1 0H6.9C4.65979 0 3.53969 0 2.68404 0.435974C1.93139 0.819467 1.31947 1.43139 0.935974 2.18404ZM14.2443 9.59091L8.2485 6.06817C7.91378 5.87151 7.5 6.12386 7.5 6.52465V13.4754C7.5 13.8735 7.9088 14.1261 8.24342 13.9348L14.2392 10.5068C14.5846 10.3093 14.5875 9.79258 14.2443 9.59091Z" fill="white" />
          </g>
        </svg>
      </Link>
    </li>

    <li>
      <Link
        href="https://www.tiktok.com/@nu.mx"
        color="white"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20" color="currentColor" role="img">
          <title>tiktok</title>
          <g>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.48 2.2c-.42-.73-.94-1.36-1.78-1.78C16.86 0 15.71 0 13.51 0H6.39C4.19 0 3.04 0 2.2.42 1.36.84.84 1.36.42 2.2 0 3.04 0 4.19 0 6.39v7.23c0 2.2 0 3.35.42 4.19.42.73.94 1.36 1.78 1.78.84.42 1.99.42 4.19.42h7.23c2.2 0 3.35 0 4.19-.42.73-.42 1.36-.94 1.78-1.78s.42-1.99.42-4.19V6.39c-.1-2.3-.1-3.35-.52-4.19Zm-4.03 6.47c-.1 0-.21.02-.31.02-1.19 0-2.24-.61-2.85-1.54v5.24c0 2.14-1.73 3.87-3.87 3.87s-3.87-1.73-3.87-3.87a3.867 3.867 0 0 1 4.11-3.86v1.91c-.08 0-.16-.02-.24-.02-1.09 0-1.98.88-1.98 1.98s.88 1.98 1.98 1.98 2.06-.86 2.06-1.95l.02-8.9h1.82a3.407 3.407 0 0 0 3.13 3.04v2.12Z" />
          </g>
        </svg>
      </Link>
    </li>
  </StyledBox>
);

export default FooterSocial;
